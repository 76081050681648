import { useTranslation } from 'react-i18next'
import styles from '../../css/LangSelector.module.css'
const LanguageSelector = (props) => {
    const { t, i18n } = useTranslation("global")
    const lang = i18n.language
    
    let langSelector
    let langSelectorActive

    const view = props.view;
    if(view === 'browser') {
        langSelector = styles.LanguageSelectorBrowser;
        langSelectorActive = styles.LanguageSelectorActiveBrowser;
    } else {
        langSelector = styles.LanguageSelectorMobile;
        langSelectorActive = styles.LanguageSelectorActiveMobile;
    }
     
    

    return <>
    
    {lang === 'it'
        ? <button className={langSelectorActive}   onClick={() => i18n.changeLanguage("it")}><span>Ita</span></button> 
        : <button className={langSelector}   onClick={() => i18n.changeLanguage("it")}><span>Ita</span></button> 
        }
    {lang === 'en' || lang === 'auto'
        ? <button className={langSelectorActive}   onClick={() => i18n.changeLanguage("en")}><span>Eng</span></button> 
        : <button className={langSelector}   onClick={() => i18n.changeLanguage("en")}><span>Eng</span></button> 
        }
        </>
}

export default LanguageSelector;