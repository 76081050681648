import Footer from "../components/utils/Footer";
import Header from "../components/utils/Header";
import Nav from "../components/utils/Nav";
import InfoHouse from "../components/home/InfoHouse";
import InfoBreakfast from "../components/home/InfoBreakfast";
import banner from '../media/banner/home.webp';
import GoToBooking from "../components/utils/GoToBooking";
import styles from '../css/Home.module.css';
import css from '../css/LandingPage.module.css';
import logo from '../media/logo-bbdesign.png';
import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

const LandingPage = () => {
    const [isVisible, setIsVisible] = useState(true);
    const { t } = useTranslation("global")
    useEffect(() => {
        const timeout = setTimeout(() => {
          setIsVisible(false);
        }, 6000);

        if (isVisible) {
            // Set the overflow property to hidden
            document.documentElement.style.overflow = 'hidden';
        } else {
          // Reset overflow to default when isVisible is false
          document.documentElement.style.overflow = 'auto';
      }

        return () => clearTimeout(timeout);
      }, [isVisible]);

        return <>
    {isVisible && (

        <motion.div className={css.opening}
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{
          duration: 3.5,
          delay: 4.5,
          ease: [0, 0.21, 0.2, 1.01]
        }}>
          
        <motion.img
        initial={{ opacity: 0, y: 300, scale:3 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        transition={{
          duration: 4,
          delay: 0.5,
          ease: [0, 0.21, 0.2, 1.01]
        }}
        src={logo} alt='logo of bnb design bologna' />
        <motion.p className={css.quote}
        initial={{ opacity: 0  }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 4,
          delay: 2,
          ease: [0, 0.21, 0.2, 1.01]
        }}>
          <p className={styles.quote}>…per scoprire il senso più profondo di un luogo, è necessario dormire in una casa di quel luogo.</p>
          <p>- Hermann Hesse -</p>
        </motion.p>
        </motion.div>
    )}
        <Nav />
        <Header title="B&B Art Loft" img={banner} />
        <div className={styles.home}>
            <div>
                <p className={styles.subheading}>{t("home.subheading")}</p>
            </div>
            <div></div>
        </div>
        <InfoHouse />
        <GoToBooking img={banner} />
        <InfoBreakfast />
        <Footer />
    </>
    
}

export default LandingPage;