import img0 from '../../media/room2/B&B_Design-10.jpg';
import img1 from '../../media/room2/B&B_Design-11.jpg';
import img2 from '../../media/room2/B&B_Design-12.jpg';
import img3 from '../../media/room2/B&B_Design-13.jpg';
import img4 from '../../media/room2/B&B_Design-14.jpg';
import img5 from '../../media/room2/B&B_Design-15.jpg';
import img6 from '../../media/room2/B&B_Design-16.jpg';
import img7 from '../../media/room2/B&B_Design-17.jpg';
import img8 from '../../media/room2/B&B_Design-18.jpg';

export const CarouselRoomTwo = [
  {img0},
  {img1},
  {img2},
  {img3},
  {img4},
  {img5},
  {img6},
  {img7},
  {img8}
];