import logo from '../../media/logo-bbdesign.png';
import styles from '../../css/Mobile.module.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LangSelector from './LangSelector';
import { motion } from 'framer-motion'
const Mobile = () => {
    
    const { t } = useTranslation("global")
    const [showNav, setShownav] = useState(false)

    const toggleShowNav = () =>{
        setShownav(!showNav)
    }

    const [randomWidth1, setRandomWidth1] = useState('5%');
    const [randomWidth2, setRandomWidth2] = useState('5%');
    const [randomWidth3, setRandomWidth3] = useState('5%');

    useEffect(() => {
        const generateRandomWidth = () => {
          return `${Math.floor(Math.random() * 50 + 5)}%`;
        };
    
        const interval = setInterval(() => {
          setRandomWidth1(generateRandomWidth()); // Update width for div 1
          setRandomWidth2(generateRandomWidth()); // Update width for div 2
          setRandomWidth3(generateRandomWidth()); // Update width for div 3
        }, 500); // Change width every 1 second
    
        return () => clearInterval(interval);
      }, []);

    return <div className={styles.top}>
                <div className={styles.container}>
                    <div className={styles.menu}>
                        <Link to="/home"><img src={logo} alt='logo of bnb design bologna' className={styles.logo}/></Link>
                        <div className={showNav ? styles.menuLinkHide : styles.menuLink} onClick={toggleShowNav}>
                            <motion.div 
                                style={{width: randomWidth1}} 
                                animate={{ width: randomWidth1 }}
                            transition={{duration: .016, ease: 'easeInOut'}}>
                            </motion.div>
                            <motion.div 
                                style={{width: randomWidth2}} 
                                animate={{width: randomWidth2 }}
                                transition={{duration: .016, ease: 'easeInOut'}}>
                            </motion.div>
                            <motion.div 
                                style={{width: randomWidth3}} 
                                animate={{width: randomWidth3}}
                                transition={{duration: .016, ease: 'easeInOut'}}>
                            </motion.div>
                        </div>
                    </div>
                    <nav className={showNav ? styles.navMobileShow : styles.navMobile}>
                    <li className={styles.link}><Link to="/booking">{t('nav.booking')}</Link></li>
                    <li className={styles.link}><Link to="/rooms">{t('nav.rooms')}</Link></li>
                    <li className={styles.link}><Link to="/info">Info</Link></li>
                    <li className={styles.link}><LangSelector view="mobile" /></li>
                    </nav>
                </div>
            </div>
}

export default Mobile;