import { useLocation } from 'react-router-dom';
import styles from '../../css/Header.module.css';
import { isBrowser } from 'react-device-detect';
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from 'react';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';


const Header = (props) => {

    const { t } = useTranslation("global");
    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end end"]
    })

    const translateY = useTransform(scrollYProgress, [0.8, 1], [0, -50]);
    const opacity = useTransform(scrollYProgress, [0.8, 1], [0, 1]);

    let url = useLocation();

    //{isBrowser && url.pathname === '/' | url.pathname === '/home' ? <a href="/" _blank className={styles.button}>Virtual Tour</a> : ''} AGGIUNGI POSTUMO

    return <> 
    <header ref={ref}>
        <div className={styles.banner} style={{background: `url("${props.img}") center center / cover no-repeat`}}>
          
        </div>
        <div className={styles.fullHeight}>
            <div>
            <motion.h1 style={{ translateY, opacity }} transition={{
            duration: 4 // Adjust duration of transition
            }}>{props.title}</motion.h1>
            </div>
        </div>
        
    </header>

        <CookieConsent
        location="bottom"
        buttonText={t("cookie.button")}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", background:"#fff" }}
        expires={150}
        >
        {t("cookie.text")}
        </CookieConsent>
    </>
}

export default Header;