import React, { useState } from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";


const Gallery = ({images}) => {
    const [data, setData] = useState({img: '', i:0})
    const viewImage = (img, i) => {
                            setData({img, i})
                        }
    const imageAction = (action) => {
        let i = data.i;
        let actImg = Object.values(images[i])[0]
        if(action === 'close') {
            setData({img: '', i: 0})
        } else if(action === 'next') {
            i++
            if(i < images.length) {
                setData({img: actImg, i: i })
            } else {
                setData({img: Object.values(images[0])[0], i: 0})
            }
        } else if(action === 'prev') {
            i--
            if(i > 0) {
                setData({img: actImg, i: i })
            } else {
                setData({img: Object.values(images[images.length - 1])[0], i: images.length - 1})
            }
        }
    }
    return (
        <>
        {data.img &&
            <div style={{
                width: '100%',
                height: '100vh',
                background: 'rgb(0 0 0 /75%)',
                position: 'fixed',
                top: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                zIndex: 4,
            }}>
                <button style={{position: 'absolute', top: '10px', right: '10px', cursor: 'pointer'}} onClick={() => imageAction('close')}>X</button>
                <button onClick={()=> imageAction('prev')}>Prev</button>
                <img src={Object.values(images[data.i])[0]} style={{width: 'auto', maxWidth: '90%', maxHeight: '90%'}} alt="" />
                <button onClick={()=> imageAction('next')}>Next</button>
            </div>
        }
        <ResponsiveMasonry
            columnsCountBreakPoints={{350:1, 750:2,900:3}} >

                <Masonry gutter="20px">
                    {images.map((image, i) => {

                        return(
                            <img
                                key={i} src={Object.values(image)}
                                style={{width: "100%", display: "block", cursor: "pointer"}}
                                alt=""
                                onClick={() => viewImage(Object.values(image), i)} />
                        )
                    })}
                </Masonry>

        </ResponsiveMasonry>
        </>
    )
}

export default Gallery;