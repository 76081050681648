import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { isBrowser } from 'react-device-detect';

const CarouselDefault = (props) => {

  return (
    <Carousel showThumbs={false} showIndicators={false}>
        {props.gallery.map((item, index) => {
          let url = `${index+1}`;
          return  <>
          <div key={index}>
            <img src={item.image[`img${url}`]} alt="" width={isBrowser ? "100%" : "300px"} height={isBrowser ? "100%" : "188px"} />
          </div>
          </>
        })}
    </Carousel>
);
}

export default CarouselDefault;