import styles from '../../css/Footer.module.css';
import { useTranslation } from 'react-i18next';

const Footer = ()=> {
    const { t } = useTranslation("global")
    return <>
        <footer className={styles.footer}>
        <div className={styles.container}>
                <p>{t("footer.copy")}</p>
                    <p><a href="tel:+393483608365">{t("footer.phone")}</a></p>
                    <p><a href="mailto:monica.bbdesign@gmail.com">{t("footer.mail")}</a></p>
                <p><a href="https://www.google.com/maps?um=1&ie=UTF-8&fb=1&gl=it&sa=X&geocode=Kat_9Rpp039HMWazbnkFs2CP&daddr=Via+Jacopo+di+Paolo,+31,+40128+Bologna+BO">{t("footer.map")}</a></p>
                <p><a href="https://mastroroccodegrandis.com/" _blank>{t("footer.mmdg")}</a></p>
        </div>
        </footer>
    </>
}

export default Footer