import Footer from "../components/utils/Footer";
import Header from "../components/utils/Header";
import Nav from "../components/utils/Nav";
import InfoHouse from "../components/home/InfoHouse";
import InfoBreakfast from "../components/home/InfoBreakfast";
import banner from '../media/banner/home.webp';
import GoToBooking from "../components/utils/GoToBooking";
import styles from '../css/Home.module.css';
import { useTranslation } from 'react-i18next';

const LandingPage = () => {
    const { t } = useTranslation("global")

    return <>
        <Nav />
        <Header title="B&B Art Loft" img={banner} />
        <div className={styles.home}>
            <div>
                <p className={styles.subheading}>{t("home.subheading")}</p>
            </div>
            <div></div>
        </div>
        <InfoHouse />
        <GoToBooking img={banner} />
        <InfoBreakfast />
        <Footer />
    </>
}

export default LandingPage;