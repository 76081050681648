import styles from '../../css/InfoLocation.module.css';
import Collapsible from 'react-collapsible';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoArray } from './InfoArray';

const InfoLocation = () => {
    const { i18n } = useTranslation("global");
    const lang = i18n.language;
    const [countFirst, setCountFirst] = useState(0);

    return <>
        <h2 className={styles.title}>{InfoArray[2].title}</h2>
        <div className={styles.container}>
            <div>
                <ul>
                    <li>
                        {
                            InfoArray[2].items.map((item, index) => {

                                if(lang === 'en' || lang === 'auto'){
                                    return <Collapsible key={index}
                                                trigger={item.questionEng}
                                                triggerWhenOpen={item.triggerOpenEng}
                                                transitionTime="800" open={countFirst === index} onTriggerOpening ={() => setCountFirst(index)}>
                                                    <p>{item.answerEng}</p>
                                                </Collapsible>
                                } else{
                                    return <Collapsible key={index}
                                                trigger={item.questionIta}
                                                triggerWhenOpen={item.triggerOpenIta}
                                                transitionTime="800" open={countFirst === 1} onTriggerOpening ={() => setCountFirst(1)}>
                                                <p>{item.answerIta}</p>
                                            </Collapsible>
                                }
                                
                            })
                        }
                    </li>
                    
                </ul>
            </div>
        </div>
    </>
}

export default InfoLocation