import Header from "../components/utils/Header";
import banner from '../media/banner/rooms.webp';
import Nav from "../components/utils/Nav";
import styles from '../css/Rooms.module.css';
import { CarouselRoomOne } from "../components/room/CarouselRoomOne";
import { CarouselRoomTwo } from "../components/room/CarouselRoomTwo";
import Gallery from "../components/UI/Gallery";
import Footer from "../components/utils/Footer";
import GoToBooking from "../components/utils/GoToBooking";
import { useTranslation } from 'react-i18next';

const Rooms = () => {
    const { t } = useTranslation("global")

    return <>
        <Nav active="room" />
        <Header title={t("rooms.title")} img={banner} />
        <p className={styles.subtitle}>{t("rooms.subtitle")}</p>
        <h2 className={styles.title}>Sliding Door</h2>
        <div className={styles.container}>
            
                <p className={styles.roomText}>{t("rooms.one.text")}</p>

            <Gallery images={CarouselRoomOne} />
        </div>
        <GoToBooking img={banner} />
        <h2 className={styles.title}>Double Room</h2>
        <div className={styles.container}>
        <p className={styles.roomText}>{t("rooms.two.text")}</p>
            <Gallery images={CarouselRoomTwo} />
        </div>
        <Footer />
    </>

}

export default Rooms;