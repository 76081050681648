import { Link } from 'react-router-dom';
import styles from '../../css/GoToBooking.module.css'
import { motion } from 'framer-motion'
import { useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { isBrowser } from 'react-device-detect';
const GoToBooking = (props) => {
    const { t } = useTranslation("global")
    const constraintsRef = useRef(null)
    const [ref, inView] = useInView({
        triggerOnce: true, // Only trigger once
      });

    return <>
    <div className='separator'></div>
    <motion.div className={styles.container} ref={constraintsRef}>
        <motion.div
        className={styles.backgroundImage}
        style={{
            background: `url(${props.img}) no-repeat ${isBrowser ? 'fixed center' : 'center'}`,
            height: '50vh',
            backgroundSize: '100%'
          }}
        drag
        dragConstraints={constraintsRef} ref={ref}>
                <motion.div initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}>
            <Link to="/booking"><span>{t("gotobooking.booking")}</span></Link>
        </motion.div>
        </motion.div>
    </motion.div>
    <div className='separator'></div>
    </>
}

export default GoToBooking;