import Header from "../components/utils/Header";
import banner from '../media/banner/info.webp';
import Nav from "../components/utils/Nav";
import Footer from "../components/utils/Footer";
import InfoLocation from "../components/info/InfoLocation";
import GoToBooking from "../components/utils/GoToBooking";
import InfoFeature from "../components/info/InfoFeature";
import InfoPress from "../components/info/InfoPress";
import InfoShooting from "../components/home/InfoShooting";


const Info = () => {



    return <>
        <Nav active="info" />
        <Header title="Info" img={banner} />
        <InfoFeature />
        <InfoLocation  />
        <GoToBooking img={banner} />
        <InfoShooting />
        <Footer />
    </>
}

export default Info;