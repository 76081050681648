import img1 from './colazione1.webp';
import img2 from './colazione2.webp';
import img3 from './colazione3.webp';

export const InfoBreakfastGallery = [
    {
        image: {img1}
    },
    {
        image: {img2}
    },
    {
        image: {img3}
    }

]