export const InfoArray = {
    0: {
        titleIta: 'Servizi e luoghi d\'interesse nelle vicinanze',
        titleEng: 'Services and places of interest nearby',
        arr: [
            {
                name: "Bologna Fiera",
                distance: "1.8km",
                link: "https://www.google.com/maps/dir/Via+Jacopo+di+Paolo,+31,+40128+Bologna+BO/Bologna+Fiere+ingresso+Ovest+Costituzione,+Piazza+della+Costituzione,+Bologna,+Metropolitan+City+of+Bologna/@44.5160536,11.3467024,15z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x477fd369aa071d1f:0x9df85f21f691c48b!2m2!1d11.3510052!2d44.5207165!1m5!1m1!1s0x477fd33b7c208b0d:0x24caa57c7ca931f6!2m2!1d11.3627454!2d44.5115355!3e2?entry=ttu"
            },
            {
                name: "Villa Erbosa",
                distance: "0.7km",
                link: "https://www.google.com/maps/dir/Via+Jacopo+di+Paolo,+31,+40128+Bologna+BO/Villa+Erbosa+-+Gruppo+San+Donato,+Via+dell'Arcoveggio,+50,+40129+Bologna+BO/@44.5214144,11.3416343,16z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x477fd369aa071d1f:0x9df85f21f691c48b!2m2!1d11.3510052!2d44.5207165!1m5!1m1!1s0x477e2b4818ac7ccf:0xadbab49177461cf2!2m2!1d11.3425208!2d44.5219257!3e2?entry=ttu"
            },
            {
                name: "CERN",
                distance: "3.0km",
                link: "https://www.google.com/maps/dir/Via+Jacopo+di+Paolo,+31,+40128+Bologna+BO/Istituto+Nazionale+Di+Fisica+Nucleare,+Viale+Carlo+Berti+Pichat,+6%2F2,+40127+Bologna+BO/@44.5074178,11.324769,13.19z/am=t/data=!4m14!4m13!1m5!1m1!1s0x477fd369aa071d1f:0x9df85f21f691c48b!2m2!1d11.3510052!2d44.5207165!1m5!1m1!1s0x477fd4a56deff25f:0xb92a0b7298c9e154!2m2!1d11.3568094!2d44.500438!3e2?entry=ttu"
            },
            {
                name: "Caserme Rosse",
                distance: "0.8km",
                link: "https://www.google.com/maps/dir/Via+Jacopo+di+Paolo,+31,+40128+Bologna+BO/Istituto+Nazionale+Di+Fisica+Nucleare,+Viale+Carlo+Berti+Pichat,+6%2F2,+40127+Bologna+BO/@44.5074178,11.324769,13.19z/am=t/data=!4m14!4m13!1m5!1m1!1s0x477fd369aa071d1f:0x9df85f21f691c48b!2m2!1d11.3510052!2d44.5207165!1m5!1m1!1s0x477fd4a56deff25f:0xb92a0b7298c9e154!2m2!1d11.3568094!2d44.500438!3e2?entry=ttu"
            },
            {
                name: "Parco Nord",
                distance: "1.7km",
                link: "https://www.google.com/maps/dir/Via+Jacopo+di+Paolo,+31,+40128+Bologna+BO/Istituto+Nazionale+Di+Fisica+Nucleare,+Viale+Carlo+Berti+Pichat,+6%2F2,+40127+Bologna+BO/@44.5074178,11.324769,13.19z/am=t/data=!4m14!4m13!1m5!1m1!1s0x477fd369aa071d1f:0x9df85f21f691c48b!2m2!1d11.3510052!2d44.5207165!1m5!1m1!1s0x477fd4a56deff25f:0xb92a0b7298c9e154!2m2!1d11.3568094!2d44.500438!3e2?entry=ttu"
            },
            {
                name: "Alstom Ferrovia",
                distance: "0.5km",
                link: "https://www.google.com/maps/dir/Via+Jacopo+di+Paolo,+31,+40128+Bologna+BO/Alstom+Ferroviaria+Spa,+Via+di+Corticella,+Bologna,+Metropolitan+City+of+Bologna/@44.5198261,11.3469345,17z/data=!4m14!4m13!1m5!1m1!1s0x477fd369aa071d1f:0x9df85f21f691c48b!2m2!1d11.3510052!2d44.5207165!1m5!1m1!1s0x477fd368a5a06159:0x4f3493ae76e4c1e4!2m2!1d11.3487594!2d44.5175111!3e2?entry=ttu"
            },
            {
                name: "Unipol Group",
                distance: "1.6km",
                link: "https://www.google.com/maps/dir/Via+Jacopo+di+Paolo,+31,+40128+Bologna+BO/Unipol+Gruppo+Spa,+Via+Stalingrado,+37,+40128+Bologna+BO/@44.5159023,11.3420024,15z/data=!4m14!4m13!1m5!1m1!1s0x477fd369aa071d1f:0x9df85f21f691c48b!2m2!1d11.3510052!2d44.5207165!1m5!1m1!1s0x477fd35bb8400001:0xc2353c534387079!2m2!1d11.3574963!2d44.5110759!3e2?entry=ttu"
            },
            {
                name: "Data Valley Tecnopolo",
                distance: "1.0km",
                link: "https://www.google.com/maps/dir/Via+Jacopo+di+Paolo,+31,+40128+Bologna+BO/Tecnopolo+di+Bologna+-+Manifattura+Tabacchi,+Via+Stalingrado,+86,+40128+Bologna+BO/@44.5194556,11.3509966,16z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x477fd369aa071d1f:0x9df85f21f691c48b!2m2!1d11.3510052!2d44.5207165!1m5!1m1!1s0x477fd36515fa64d1:0xf1d6ba1ca535de41!2m2!1d11.3599049!2d44.5198776!3e2?entry=ttu"
            },
        ]
    },
    1: {
        titleIta: 'Regole',
        titleEng: 'Rules',
        arr: [
            {
                text_it: 'Comunicare almeno 24 ore prima, l’orario d’arrivo',
                text_en: 'If possible, tell us the time of arrival 24 hours before',
                text_auto: 'If possible, tell us the time of arrival 24 hours before'
            },
            {
                text_it: 'La colazione viene servita dalle 7.30 alle 10.30',
                text_en: 'Breakfast is served between 7.30 and 10.30',
                text_auto: 'Breakfast is served between 7.30 and 10.30'
            },
            {
                text_it: 'Check-in entro le 21.00, check-out entro 11.30',
                text_en: 'Check-in until 9PM, checko-out before 11.30',
                text_auto: 'Check-in until 9PM, checko-out before 11.30'
            },
            {
                text_it: 'Sia la tassa di soggiorno che il parcheggio interno non sono inclusi nella tariffa',
                text_en: 'Tourist Tax and Parking fee is not included in the price',
                text_auto: 'Tourist Tax and Parking fee is not included in the price'
            },
            {
                text_it: 'Per una prenotazione superiore ai 3 giorni, è richiesto un anticipo, tramite bonifico del 50%',
                text_en: 'For a booking longer than 3 days, a 50% advance payment is required via bank transfer.',
                text_auto: 'For a booking longer than 3 days, a 50% advance payment is required via bank transfer.'
            },
            {
                text_it: 'In tutta la struttura c’è divieto di fumare',
                text_en: 'Smoking is strictly forbidden inside the house',
                text_auto: 'Smoking is strictly forbidden inside the house'
            },
            {
                text_it: 'Se in compagnia di animali, dichiarare la taglia',
                text_en: 'If in the company of animals, declare the size',
                text_auto: 'If in the company of animals, declare the size'
            },
        ]
    },
    2: {
        title: 'FAQ',
        items: [
            {
                questionIta: 'Sono accettati i cani nel BB? +',
                questionEng: 'Are dogs allowed in the BB? +',
                triggerOpenIta: 'Sono accettati i cani nel BB? -',
                triggerOpenEng: 'Are dogs allowed in the BB? -',
                answerIta: 'Si, abbiamo anche un ampio cortile e una terrazza dove possono stare liberi in sicurezza.',
                answerEng: 'Yes, we also have a large courtyard and a terrace where they can stay free in safety.'
            },
            {
                questionIta: 'C’è un ristorante da raggiungere a piedi dal BB? +',
                questionEng: 'Is there a restaurant I can walk to from the BB? +',
                triggerOpenIta: 'C’è un ristorante da raggiungere a piedi dal BB? -',
                triggerOpenEng: 'Is there a restaurant I can walk to from the BB? -',
                answerIta: 'Si ci sono due ristoranti pizzerie, Grotta Azzurra e Reginella a pochi passi.',
                answerEng: 'Yes there are two pizzeria restaurants, Grotta Azzurra and Reginella within walking distance.'
            },
            {
                questionIta: 'Ci son ristoranti con cucina tipica bolognese nelle vicinanze del BB? +',
                questionEng: 'Are there restaurants with typical Bolognese cuisine near the BB? +',
                triggerOpenIta: 'Ci son ristoranti con cucina tipica bolognese nelle vicinanze del BB? -',
                triggerOpenEng: 'Are there restaurants with typical Bolognese cuisine near the BB? -',
                answerIta: 'Si ci sono almeno 3 ottime trattorie con cucina tipica bolognese: Antica Trattoria della Gigina, le Golosità di Nonna Aurora, Ristorante Papa Re.',
                answerEng: 'Yes, there are at least 3 excellent trattorias with typical Bolognese cuisine: Antica Trattoria della Gigina, le Golosì di Nonna Aurora, Ristorante Papa Re.'
            },
            {
                questionIta: 'Ci sono parchi nelle vicinanze dove andare a correre nel verde? +',
                questionEng: 'Are there any parks nearby where you can go running in the greenery? +',
                triggerOpenIta: 'Ci sono parchi nelle vicinanze dove andare a correre nel verde? -',
                triggerOpenEng: 'Are there any parks nearby where you can go running in the greenery? -',
                answerIta: 'Si ci sono 4 parchi. In particolare parchi e distanze dal BB sono i seguenti: fino al Parco Caserme Rosse, 900 mt. A piedi 10 minuti. Fino al Giardino Guido Rossa, 800 mt. A piedi 8 minuti',
                answerEng: 'Yes there are 4 parks. In particular, the parks and distances from the BB are as follows: up to the Caserme Rosse Park, 900 m. On foot 10 minutes. To the Guido Rossa Garden, 800 m. On foot 8 minutes'
            },
            {
                questionIta: 'Arrivo in Stazione centrale a Bologna, come posso raggiungere il B&B? +',
                questionEng: 'I arrive at the central station in Bologna, how can I reach the B&B? +',
                triggerOpenIta: 'Arrivo in Stazione centrale a Bologna, come posso raggiungere il B&B? -',
                triggerOpenEng: 'I arrive at the central station in Bologna, how can I reach the B&B? -',
                answerIta: 'Usciti dalla stazione Centrale raggiungere la fermata Autostazione prendere o la linea 27 o 97 direzione Corticella e fermarsi Istituto Aldini Valeriani. Vicino a 20 metri c’è via jacopo di Paolo che bisogna percorre tutta fino al primo incrocio. La struttura è sulla destra.',
                answerEng: 'Once out of the Central Station, reach the Autostazione stop, take either line 27 or 97 towards Corticella and stop at Istituto Aldini Valeriani. Close to 20 meters away is Via Jacopo di Paolo which you have to follow until the first intersection. The property is on the right.'
            },
            {
                questionIta: 'Il BB dispone di un parcheggio interno? +',
                questionEng: 'Does the BB have internal parking? +',
                triggerOpenIta: 'Il BB dispone di un parcheggio interno? -',
                triggerOpenEng: 'Does the BB have internal parking? -',
                answerIta: 'Si solo per un veicolo, quindi alla prenotazione è consigliato farne richiesta. Il portale d’accesso al parcheggio è largo solo 220 cm. Suv e furgoni di grandi dimensioni non riescono ad entrare.',
                answerEng: 'Yes, only for one vehicle, so it is recommended to make a request when booking. The access portal to the car park is only 220 cm wide. SUVs and large vans cannot enter.'
            },
            {
                questionIta: 'È possibile parcheggiare fuori dalla ZTL vicino al Centro Città? +',
                questionEng: 'Is it possible to park outside the restricted traffic zone near the city center? +',
                triggerOpenIta: 'È possibile parcheggiare fuori dalla ZTL vicino al Centro Città? -',
                triggerOpenEng: 'Is it possible to park outside the restricted traffic zone near the city center? -',
                answerIta: 'Si esistono due capienti parcheggi nelle vicinanze del Centro città. Il più vicino al Centro è il Parcheggio interrato di Piazza VIII Agosto, in alternativa c’è il Parcheggio del Centro Autostazione di Bologna. Da entrambi si arriva nel centro città in pochi minuti a piedi.',
                answerEng: 'There are two large car parks near the city centre. The closest to the center is the underground car park in Piazza VIII Agosto, alternatively there is the car park at the Bologna Bus Station Centre. From both you can reach the city center in a few minutes on foot.'
            }
        ]
    }
}