import img0 from '../../media/room1/B&B_Design-1.jpg';
import img1 from '../../media/room1/B&B_Design-2.jpg';
import img2 from '../../media/room1/B&B_Design-3.jpg';
import img3 from '../../media/room1/B&B_Design-4.jpg';
import img4 from '../../media/room1/B&B_Design-5.jpg';
import img5 from '../../media/room1/B&B_Design-6.jpg';
import img6 from '../../media/room1/B&B_Design-7.jpg';


export const CarouselRoomOne = [
  {img0},
  {img1},
  {img2},
  {img3},
  {img4},
  {img5},
  {img6}
];