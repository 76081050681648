import styles from '../../css/InfoBreakfast.module.css'
import CarouselDefault from "../UI/Carousel";
import {InfoShootingGallery} from '../../media/images/gallery/shooting/InfoShooting';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const InfoShooting = (props) => {
    const { t } = useTranslation("global")
    return <>
        <h2 className={styles.breakfast}>{t("infoShooting.title")}</h2>
        <div className={styles.container}>
            <div className={styles.textContainer}>
                <p>{t("infoShooting.text")} <Link to="/booking">form</Link></p>
            </div>
            <CarouselDefault gallery={InfoShootingGallery} />
        </div>
    </>
}

export default InfoShooting;