import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import styles from '../../css/Form.module.css';
import { useTranslation } from 'react-i18next';

const Form = () => {
  const { t } = useTranslation("global");

  const form = useRef();
  const [disableFields, setDisableFields] = useState(false); // State variable to control field disabling
  const [checkinDate, setCheckinDate] = useState('');

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();

    // Add leading zero if month/day is less than 10
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);

    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    if (data.parking === 'on') {
      data.parking = 'yes';
    } else {
      data.parking = 'no';
    }

    if (data.shooting === 'on') {
      data.shooting = 'yes';
    } else {
      data.shooting = 'no';
    }

    emailjs
      .sendForm('default_service', 'template_utrnnpi', form.current, {
        publicKey: 'ZBtI_i3yR1Sd0vAgW',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          alert(t("booking.submitSuccess"))
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert(t("booking.submitFailure"))
        },
      );

    e.target.reset();
  };

  // Function to handle the change in shooting checkbox
  const handleShootingChange = (e) => {
    setDisableFields(e.target.checked);
  };

  const handleCheckinChange = (e) => {
    setCheckinDate(e.target.value);
  };



  return (
    <div className={styles.container}>
    <form ref={form} onSubmit={sendEmail}>
      <label>{t("booking.name")}</label>
      <input type="text" name="name" required />

      <label>{t("booking.surname")}</label>
      <input type="text" name="surname" required />

      <label>{t("booking.email")}</label>
      <input type="email" name="email" required />

      <label>{t("booking.tel")}</label>
      <input type="tel" name="telephone" required />

      <div className={styles.checkbox}>
        <label>{t("booking.shooting")}</label>
        <input type="checkbox" name="shooting" onChange={handleShootingChange} />
      </div>

      <label>{t("booking.checkin")}</label>
      <input type="date" name="checkin" disabled={disableFields} min={getCurrentDate()} onChange={handleCheckinChange} required />

      <label>{t("booking.checkout")}</label>
      <input type="date" name="checkout" disabled={disableFields} min={checkinDate} required />

      <label>{t("booking.guests")}</label>
      <input type="number" name="guests" disabled={disableFields} min="1" max="6" required />

      <label>{t("booking.breakfast")}</label>
      <select name="breakfast" disabled={disableFields}>
        <option value="italiana">{t("booking.italian")}</option>
        <option value="continentale">{t("booking.continental")}</option>
        <option value="vegetariana">{t("booking.vegetarian")}</option>
      </select>

      <div className={styles.checkbox}>
        <label>{t("booking.parking")}</label>
        <input type="checkbox" name="parking" disabled={disableFields} />
      </div>

      <label>{t("booking.message")}</label>
      <textarea name="message" />

      <input type="submit" value={t("booking.submit")} />
      
    </form>
    </div>
  );
};

export default Form;
