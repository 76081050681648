import styles from '../../css/InfoHouse.module.css'
import CarouselDefault from "../UI/Carousel";
import {InfoGallery} from '../../media/images/gallery/casa/InfoGallery';
import { useTranslation } from 'react-i18next';



const InfoHouse = () => {
    const { t } = useTranslation("global")
    return <>
    <div className='separator'></div>
        <div className={styles.container}>
            <div className={styles.textContainer}>
                <h3 className={styles.title}>{t("infoHouse.title")}</h3>
                <p className={styles.text}>{t("infoHouse.text")}</p>
            </div>
            
            <CarouselDefault gallery={InfoGallery} />
        </div>
    </>
}

export default InfoHouse;