import styles from '../../css/InfoBreakfast.module.css'
import {InfoBreakfastGallery} from '../../media/images/gallery/colazioni/InfoBreakfast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';

console.log(InfoBreakfastGallery);

console.log(InfoBreakfastGallery[1].image.img1);
const InfoBreakfast = () => {
    const { t } = useTranslation("global")
    return <>
        <div className={styles.container}>
            
                <h2 className={styles.title}>{t("infoBreakfast.title")}</h2>
                <p className={styles.text}>{t("infoBreakfast.text")}</p>
                <div className={styles.breakfastFlex}>
                    <div>
                        <img src={InfoBreakfastGallery[0].image.img1} alt="Italian Breakfast" width={isBrowser ? "100%" : "300px"} height={isBrowser ? "100%" : "188px"} />
                    </div>
                    <div>
                        <h2 className={styles.title}>{t("infoBreakfast.titleItalian")}</h2>
                        <p className={styles.text}>{t("infoBreakfast.italian")}</p>
                    </div>
                </div>
                <div className={styles.breakfastFlex}>
                    <div>
                        <img src={InfoBreakfastGallery[1].image.img2} alt="Italian Breakfast" width={isBrowser ? "100%" : "300px"} height={isBrowser ? "100%" : "188px"} />
                    </div>
                    <div>
                        <h2 className={styles.title}>{t("infoBreakfast.titleContinental")}</h2>
                        <p className={styles.text}>{t("infoBreakfast.continental")}</p>
                    </div>
                </div>
                <div className={styles.breakfastFlex}>
                    <div>
                        <img src={InfoBreakfastGallery[2].image.img3} alt="Italian Breakfast" width={isBrowser ? "100%" : "300px"} height={isBrowser ? "100%" : "188px"} />
                    </div>
                    <div>
                        <h2 className={styles.title}>{t("infoBreakfast.titleVegetarian")}</h2>
                        <p className={styles.text}>{t("infoBreakfast.vegetarian")}</p>
                    </div>
                </div>
                <p>{t("infoBreakfast.extra")} <Link to="/booking">form</Link></p>
            </div>
    </>
}

export default InfoBreakfast;