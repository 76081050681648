import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Homepage from './pages/Homepage';
import Info from './pages/Info';
import Rooms from './pages/Rooms';
import Booking from './pages/Booking';

const router = createBrowserRouter([
  { path: '/', element: <LandingPage />},
  { path: '/home', element: <Homepage />},
  { path: '/info', element: <Info />},
  { path: '/rooms', element: <Rooms />},
  { path: '/booking', element: <Booking />}
])

function App() {
  return <RouterProvider router={router} />
  ;
}

export default App;