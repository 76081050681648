import logo from '../../media/logo-bbdesign.png';
import { Link } from 'react-router-dom';
import styles from '../../css/Nav.module.css';
import { isBrowser, isMobile } from 'react-device-detect';
import Mobile from './Mobile';
import { useTranslation } from 'react-i18next';
import LangSelector from './LangSelector'

const Nav = (props) => {
    const { t } = useTranslation("global")

    if(isBrowser) {
        return <div className={styles.sticky}>
        <nav className={styles.nav}>
        <li className={props.active === "booking" ? styles.linkActive : styles.link}><Link to="/booking">{t("nav.booking")}</Link></li>
        <li className={props.active === "room" ? styles.linkActive : styles.link}><Link to="/rooms">{t("nav.rooms")}</Link></li>
        <li className={styles.link}><Link to="/home"><img src={logo} alt='logo of bnb design bologna' className={styles.logo}/></Link></li>
        <li className={props.active === "info" ? styles.linkActive : styles.link}><Link to="/info">Info</Link></li>
        <li className={styles.link}>
            <LangSelector view="browser" />
        </li>

            

      
        </nav>
        </div>
    }

    if(isMobile) {
        return <>
        <Mobile/>
        </>

    }


}

export default Nav;