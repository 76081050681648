import styles from "../../css/InfoRestaurant.module.css";
import { InfoArray } from "./InfoArray";
import { useTranslation } from "react-i18next";

const InfoFeature = () => {

  const { i18n } = useTranslation("global");
    const lang = i18n.language;

    console.log(lang)

  // Render the JSX with the fetched data
  return <>
    <div className={styles.container}>
      <div>
        <div className={styles.flex}>
          <div>
            {lang === "it" ? <h2>{InfoArray[0].titleIta}</h2> : <h2>{InfoArray[0].titleEng}</h2> }
            <ul>
            {InfoArray[0].arr.map((item, i) => <li key={i}><a href={item.link} rel="noreferrer" target="_blank"><p>{item.name} <span>{item.distance}</span></p></a></li>)}
            </ul>
          </div>
          <div>
          {lang === "it" ? <h2>{InfoArray[1].titleIta}</h2> : <h2>{InfoArray[1].titleEng}</h2> }
          <ul>
          {InfoArray[1].arr.map((item, i) => (
            <li key={i}>
                <p>{lang === "en" || lang === "auto" ? item.text_en : item.text_it}</p>
            </li>
          ))}
          </ul>
          </div>
        </div>
      </div>
    </div>
  </>;
};

export default InfoFeature;


