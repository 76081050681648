import Header from "../components/utils/Header";
import banner from '../media/banner/booking.webp';
import Nav from "../components/utils/Nav";
import Footer from "../components/utils/Footer";
import  Form  from "../components/book/Form";
import { useTranslation } from 'react-i18next';
import styles from '../css/Booking.module.css';

const Booking = () => {

    const { t } = useTranslation("global")
    return <>
        <Nav active="booking" />
        <Header title={t("booking.title")} img={banner} />
        <div className={styles.contacts}>
            <h2>{t("booking.contactTitle")}</h2>
            <p>{t("booking.mobile")}: <a href="tel:+393483608365">+393483608365</a></p>
            <p>{t("booking.phone")}: <a href="tel:0510560830">0510560830</a></p>
        </div>
        <Form />
        <Footer />
    </>
}

export default Booking;