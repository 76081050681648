import img1 from './casa1.webp';
import img2 from './casa2.webp';
import img3 from './casa3.webp';
import img4 from './casa4.webp';
import img5 from './casa5.webp';
import img6 from './casa6.webp';
import img7 from './casa7.webp';
import img8 from './casa8.webp';
import img9 from './casa9.webp';
import img10 from './casa10.webp';



export const InfoGallery = [
    {
        image: {img1},

    },
    {
        image: {img2},
       
    },
    {
        image: {img3},
        
    },
    {
        image: {img4},
        
    },
    {
        image: {img5},
        
    },
    {
        image: {img6},
        
    },
    {
        image: {img7},
     
    },
    {
        image: {img8},
        
    },
    {
        image: {img9},
       
    },
    {
        image: {img10},
        
    }
]