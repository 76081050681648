import img1 from './shooting1.jpeg'
import img2 from './shooting2.jpeg'
export const InfoShootingGallery = [
    {
        image: {img1}
    },
    {
        image: {img2}
    }

]